import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --primary-font-family: Apercu, sans-serif;
    --secondary-font-family: Apercu, sans-serif;
  }

  html {
    font-family: var(--primary-font-family);
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;
    font-style: normal;
    -webkit-tap-highlight-color: transparent;
  }
`;
