import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

const BannerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const HomeBannerImage = styled.img`
  margin-bottom: 16px;
  width: 100%;
  max-width: 1065px;
  ${breakpoints.md} {
    width: auto;
  }
`;

interface HomeBannerProps {
  src: string;
  alt: string;
}

export const HomeBanner = ({ src, alt }: HomeBannerProps) => {
  return (
    <BannerWrapper>
      <HomeBannerImage src={src} alt={alt} />
    </BannerWrapper>
  );
};
