import { colors } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

const PrivacyPolicyAnchor = styled.div`
  color: ${colors.black};
  margin-left: 4px;
  margin-right: 4px;
  line-height: 24px;
  letter-spacing: 0px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
`;

type Props = {
  customClickHandler?: (url: string) => void;
};

const PrivacyPolicyUrl = 'https://www.asurion.com/privacy-policy';

export const PrivacyPolicy = ({ customClickHandler }: Props) => {
  const handleClick = () => {
    if (customClickHandler) {
      customClickHandler(PrivacyPolicyUrl);
      return;
    }
    window.open(PrivacyPolicyUrl, '_blank');
  };

  return (
    <PrivacyPolicyAnchor
      onClick={handleClick}
      data-testid="footer-privacy-link"
      aria-describedby="new-window-warning"
      role="link"
    >
      Privacy Policy
    </PrivacyPolicyAnchor>
  );
};
