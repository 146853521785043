import styled from 'styled-components';
import WelcomeImage from './assets/welcome.svg';

const StyledImage = styled.img`
  width: 360px;
`;

export const WelcomeBanner = () => {
  return <StyledImage src={WelcomeImage} alt={'Welcome'} />;
};
