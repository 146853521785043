import styled from 'styled-components';
import Affiliates from './assets/asurion-affiliates.svg';
import Asurion from './assets/asurion.svg';
import Comparion from './assets/comparion.jpeg';

const Logo = styled.img<{ height: string }>`
  height: ${({ height }) => height};
  width: auto;
`;

export type PartnerLogoProps = {
  logoName?: 'Asurion' | 'Affiliates' | 'Comparion';
  logoHeight?: string;
};

export const PartnerLogo = ({
  logoName = 'Asurion',
  logoHeight = '30px',
}: PartnerLogoProps) => {
  const logo = {
    Asurion: Asurion,
    Comparion: Comparion,
    Affiliates: Affiliates,
  }[logoName];

  return <Logo src={logo} alt={`${logoName} logo`} height={logoHeight} />;
};
