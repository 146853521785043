import { colors } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import DefaultThumbnail from '../training-education/assets/play-video.png';
import { VerticalSpacer } from '../vertical-spacer';

const TrainingEducationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 660px;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 200px;
`;

const ThumbnailImage = styled.img`
  width: 200px;
  cursor: pointer;
`;

const Description = styled.p`
  color: ${colors.black};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  margin: 0;
`;

const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledLink = styled.a`
  color: ${colors.primaryDeep};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  text-decoration-line: underline;
`;

interface TrainingEducationProps {
  onClick: () => void;
  label: string;
  viewText?: string;
  url: string;
  src?: string;
  alt?: string;
}

export const TrainingEducation = ({
  label,
  viewText,
  url,
  src,
  alt,
  onClick,
}: TrainingEducationProps) => {
  return (
    <TrainingEducationWrapper>
      <ItemWrapper>
        <ThumbnailImage
          onClick={onClick}
          src={src || DefaultThumbnail}
          alt={alt || 'Play video'}
        ></ThumbnailImage>
        <VerticalSpacer value={0.5} />
        <Description>{label}</Description>
        <LinkWrapper>
          <StyledLink href={url} target="_blank" rel="noreferrer">
            {viewText || 'View now'}
          </StyledLink>
        </LinkWrapper>
      </ItemWrapper>
    </TrainingEducationWrapper>
  );
};
