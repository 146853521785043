import styled from 'styled-components';
import { ReactComponent as BtnClipboardCopy } from './assets/btn-clipboard-copy.svg';

const StyledDiv = styled.div`
  cursor: pointer;
`;

interface CopyToClipboardProps {
  handleClick?: () => void;
}

export const CopyToClipboard = ({ handleClick }: CopyToClipboardProps) => {
  return (
    <StyledDiv onClick={handleClick}>
      <BtnClipboardCopy />
    </StyledDiv>
  );
};
