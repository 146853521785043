import { AppDataProvider } from '@3as-affiliates/shared/web/data-access/data-store';
import { WebAppAuthProvider as AuthProvider } from '@3as-affiliates/shared/web/utils/auth-provider';
import ReactDOM from 'react-dom';
import { App } from './app';
import { environment } from './environments/environment';

const AppWrapper = () => (
  <AppDataProvider>
    <AuthProvider
      authOpenIdConfig={environment.authOpenIdConfig}
      isProd={environment.production}
    >
      <App />
    </AuthProvider>
  </AppDataProvider>
);

ReactDOM.render(<AppWrapper />, document.getElementById('root') as HTMLElement);
