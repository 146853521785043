import { Card, colors, Icon } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';

const ButtonSaleStartWrapper = styled.div`
  display: flex;
`;

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  cursor: pointer !important;
  width: 288px;
  height: 109px;
  border-radius: 8px;
  background: var(
    --gradients-light-angle-2,
    linear-gradient(285deg, #fbf2ff 0%, #ebf9ff 100%)
  );
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const StyledTitle = styled.p`
  margin: 0;
  color: ${colors.black};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
`;

interface ButtonSaleDeclineProps {
  onClick: () => unknown;
  label: string;
  iconSrc: string;
  iconSize: string;
}

export const ButtonSaleDecline = ({
  onClick,
  label,
  iconSize,
  iconSrc,
}: ButtonSaleDeclineProps) => {
  return (
    <ButtonSaleStartWrapper>
      <StyledCard onClick={onClick}>
        <CardWrapper>
          <ControlsWrapper>
            <StyledTitle>{label}</StyledTitle>
            <Icon size={iconSize} src={iconSrc}></Icon>
          </ControlsWrapper>
        </CardWrapper>
      </StyledCard>
    </ButtonSaleStartWrapper>
  );
};
