import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { PartnerLogo, PartnerLogoProps } from '../partner-logo';
import { CopyRight, PrivacyPolicy, TermsOfUse } from './components';

const Container = styled.footer`
  padding: 19px 8px 16px 8px;
  display: flex;
  font-size: 16px;
  flex-direction: column;
  align-items: start;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 19px 0px 19px 0px;
  ${breakpoints.md} {
    flex-direction: row;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 300px;
`;

type Props = PartnerLogoProps & {
  AdditionalLinkComponent?: React.ElementType<unknown>;
  customClickHandler?: (url: string) => void;
};

export const Footer = ({
  AdditionalLinkComponent,
  customClickHandler,
  logoName,
  logoHeight,
}: Props) => (
  <Container>
    <PartnerLogo logoName={logoName} logoHeight={logoHeight} />
    <ContentContainer>
      <CopyRight />
      <LinksContainer>
        <TermsOfUse customClickHandler={customClickHandler} />
        |
        <PrivacyPolicy customClickHandler={customClickHandler} />
        {AdditionalLinkComponent && `| ${(<AdditionalLinkComponent />)}`}
      </LinksContainer>
    </ContentContainer>
  </Container>
);
