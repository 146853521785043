import { PageNames } from '@3as-affiliates/partners/web/types-configs';
import { useGetAffiliate } from '@3as-affiliates/shared/web/data-access/api-client';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { LoadingSpinnerPage } from '@3as-affiliates/shared/web/features';
import {
  AuthenticationState,
  isPersonaAvailable,
  useAuth,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Routes } from './routesConfig';

export const RouteWithUserInfo: React.FC = () => {
  const { authState, getAuthUserInfo, logOut } = useAuth();
  const { appInfo } = useAppData();
  const { getAffiliate } = useGetAffiliate();

  const [userInfoFetch, setUserInfoFetch] = useState<
    'IN_PROGRESS' | 'SUCCESS' | 'ERROR'
  >('IN_PROGRESS');

  const isPersonaValid = useCallback(async () => {
    const authUserInfo = await getAuthUserInfo();
    const partner = appInfo?.partner;

    if (!partner) {
      return false;
    }

    return isPersonaAvailable(authUserInfo, partner);
  }, [appInfo?.partner, getAuthUserInfo]);

  useEffect(() => {
    isPersonaValid()
      .then((isValid) => {
        if (!isValid) {
          throw new Error('NOT_VALID_PERSONA');
        }

        return getAffiliate();
      })
      .then((affiliate) => {
        console.debug('debug - affiliate', affiliate);

        if (affiliate && affiliate.error) {
          throw new Error('NOT_VALID_AFFILIATE');
        }

        setUserInfoFetch('SUCCESS');
      })
      .catch((err) => {
        if (err.message === 'NOT_VALID_PERSONA') {
          console.error('Error: No valid Persona found');
        } else if (err.message === 'NOT_VALID_AFFILIATE') {
          console.error('Error: No valid Affiliate found');
        } else {
          console.error('Error checking persona', err.message);
        }

        setUserInfoFetch('ERROR');
        logOut();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPersonaValid, logOut]);

  if (authState === AuthenticationState.LoggedOut) {
    return <Navigate to={Routes.LoggedOutLanding} replace={true} />;
  }

  if (userInfoFetch === 'SUCCESS') {
    return <Outlet />;
  }

  return <LoadingSpinnerPage pageName={PageNames.LoadingPage} />;
};
