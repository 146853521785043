// until we decide on a content config platform like contentful, we will use this file to store the content for the comparison
import { PitchAssistConfig } from '@3as-affiliates/partners/web/components';
import HelloMessage from '../assets/hello-message.svg';
import LightBulbIdea from '../assets/light-bulb-idea.svg';
import ProtectionEnrollments from '../assets/protection-enrollments.svg';

export const pitchAssistConfig: PitchAssistConfig = {
  title: 'Pitch Assist',
  collection: [
    {
      settings: {
        header: 'Generic Pitch',
        iconSrc: 'ChatBubblesCircle',
        transitionDuration: '.4s',
        transitionTimingFunction: 'cubic-bezier(.68,.12,.24,.99)',
      },
      listItems: [
        {
          text: `
            Did you know Home+ by Asurion is one simple plan that covers
            everyday home electronics that are not covered under your home
            insurance policy!? Home+ covers repairs, replacements, or
            reimbursements for eligible home electronics such as TVs, Laptops,
            Tablets, Printers, Gaming Systems, wearables(smartwatches), Air
            Pods, etc. No receipts needed, existing and future eligible
            purchases covered under one plan. This would add an extra layer of
            protection for your everyday electronics, while never affecting
            your home insurance premiums or deductibles.
          `,
        },
        {
          text: `
            Let’s get your new home set up! Home+ by Asurion offers In Home
            installations on Eligible Devices - for just $49 per device we can
            get your new home connected! Asurion can help get your ring
            doorbell installed, Smart Home Security & Thermostats and even
            mount your new brand-new TV’s! Once enrolled you can schedule your
            visit with the Asurion team just in time for move in! And… best of
            all these services never affect your home insurance premiums or
            deductibles.
          `,
        },
        {
          text: `
            We know technical questions or issues can arise at any time and
            often at the most inconvenient time and we want to ensure we have
            you covered, especially when setting up a new home or getting
            those new home devices installed. With Asurion Home+, you will
            gain a team of experts who can answer your questions and solve
            their tech issues regardless of when they need help. Yes 24/7 live
            tech Support! This would be a great added benefit to your home,
            while never affecting your home insurance premiums or deductibles
          `,
        },
      ],
    },
    {
      settings: {
        header: 'Product Details',
        iconSrc: 'Calendar',
        transitionDuration: '.4s',
        transitionTimingFunction: 'cubic-bezier(.68,.12,.24,.99)',
      },
      listItems: [
        {
          text: `
            Protection / Tech Support – Asurion Home+
            provides 24/7 technical support and product protection for a
            variety of common home devices including, but not limited to, TVs,
            Laptops, Tablets, Printers, Gaming Systems,
            Wearables(smartwatches), Air Pods, etc., all for only $24.99 per
            month (plus taxes)! Even includes coverage for accidental damage
            from handling for select portable devices, including your eligible
            Gaming systems, Tablets, Laptops, and Wearables (smartwatches).
          `,
        },
        {
          text: `
            UBIF Services - Once enrolled, Asurion Home+
            customers instantly gain access to three complementary in-store
            services at our Asurion/UBreakifix Stores. A PC tune-up, Backup
            and Transfer content and Virus removal services for a laptop (MAC
            or PC).
          `,
        },
        {
          text: `
            Home Installs - Asurion Home+ also offers In Home
            installations on Eligible Devices - for just $49 per device! We
            can help get your ring doorbell installed, Smart Thermostat and
            even mount your new brand-new TV! Once enrolled you can schedule
            your visit with our team.
          `,
        },
      ],
    },
    {
      settings: {
        header: 'Rebuttals to common objections',
        iconSrc: 'Refresh',
        transitionDuration: '.4s',
        transitionTimingFunction: 'cubic-bezier(.68,.12,.24,.99)',
      },
      listItems: [
        {
          text: `
            I understand cost being a concern, by starting your enrollment
            today, you can potentially save money in the future. When an
            eligible device suffers a covered breakdown, Asurion will attempt
            to repair it, and if it cannot be repaired, Asurion will look to
            replace it! And the highest service fee you’ll ever have to pay is
            $99! This would add an extra layer of protection for your everyday
            electronics, while never affecting your home insurance premiums or
            deductibles.
          `,
        },
        {
          text: `
            I understand cost being a concern, by starting your enrollment
            today, you can potentially save money in the future. When an
            eligible device suffers a covered breakdown or you are on the move
            and need a new TV or Ring Camera installed, Asurion will be there
            to protect you, and the highest service fee you’ll ever have to
            pay is $99 and installation fee $49! Not only protecting your
            devices but setting up your home, while never affecting your home
            insurance premiums or deductibles.
          `,
        },
        {
          text: `
            On top of that you get 24/7 Tech Support. This means Experts are
            just a tap or call away to help answer questions or give step by
            step instructions anytime you are ready, as you know moving never
            ends not even at 2am when you are eager to get your new home set
            up! It also includes Protection, Support and Asurion in store
            services to maximize your device protection. All these amazing
            benefits of the plan, while never affecting your home insurance
            premiums or deductibles.
          `,
        },
      ],
    },
  ],
};

export const featuresConfig = [
  {
    title: 'Guided customer enrollment',
    subtitle:
      'A step-by-step process to enroll your customers in Asurion products',
    image: {
      src: ProtectionEnrollments,
      alt: 'protection',
    },
  },
  {
    title: 'Product education',
    subtitle:
      'Everything you need to know from expert-created product training to subscription details',
    image: {
      src: LightBulbIdea,
      alt: 'idea',
    },
  },
  {
    title: 'Starter scripts',
    subtitle:
      'Pre-written pitch and rebuttal content customized to the product you’re selling',
    image: {
      src: HelloMessage,
      alt: 'hello',
    },
  },
];

export const trainingMaterialsConfig = [
  {
    label: 'The Asurion Home+ Advantage',
    // TODO: move to config at later stage
    url: 'https://app.cloud.scorm.com/sc/InvitationConfirmEmail?publicInvitationId=3d4455cd-f246-4403-aa53-8218e48a5fa2',
  },
];
