import { colors } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { VerticalSpacer } from '../vertical-spacer';

const FeatureItemContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: 175px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 114px;
  height: 114px;
  border-radius: 999px;
  background: ${colors.neutralBrightest};
`;

const StyledImage = styled.img`
  width: 60px;
`;

const Title = styled.p`
  color: ${colors.black};
  font-family: Arial, serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
  margin: 0;
`;

const Subtitle = styled.p`
  color: ${colors.black};
  font-family: Arial, serif;
  font-size: 14px;
  line-height: 150%;
  margin: 0;
`;

interface FeatureItemProps {
  src: string;
  alt: string;
  title: string;
  subtitle: string;
}

export const FeatureItem = ({
  src,
  alt,
  title,
  subtitle,
}: FeatureItemProps) => {
  return (
    <FeatureItemContainer>
      <ImageContainer>
        <ImageWrapper>
          <StyledImage src={src} alt={alt}></StyledImage>
        </ImageWrapper>
      </ImageContainer>
      <VerticalSpacer value={0.5} />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </FeatureItemContainer>
  );
};
