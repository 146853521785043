export enum Routes {
  AuthRedirectCallback = '/redirect',
  LoggedInHome = '/home',
  LoggedInDecline = '/home/decline',
  LoggedOutLanding = '/landing',
}

export const defaultLoggedInRoute = Routes.LoggedInHome;

export const defaultLoggedOutRoute = Routes.LoggedOutLanding;
