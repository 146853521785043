import { PageNames } from '@3as-affiliates/partners/web/types-configs';
import { AuthRedirectCallback } from '@3as-affiliates/shared/web/features';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { DeclinePage, HomePage, LandingPage } from '../pages';
import { defaultLoggedOutRoute, Routes } from './routesConfig';
import { RouteWithUserInfo } from './RouteWithUserInfo';

export const router = createBrowserRouter([
  {
    path: Routes.AuthRedirectCallback,
    element: (
      <AuthRedirectCallback
        homePageRoute={Routes.LoggedInHome}
        pageName={PageNames.AuthRedirectPage}
      />
    ),
  },
  {
    path: Routes.LoggedOutLanding,
    element: <LandingPage />,
  },
  {
    path: Routes.LoggedInHome,
    element: <RouteWithUserInfo />,
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: 'decline',
        element: <DeclinePage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={defaultLoggedOutRoute} />,
  },
]);
