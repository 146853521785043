import {
  Accordion,
  AccordionSection,
  colors,
} from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { PitchAssistConfig } from './types';

const PitchAssistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 660px;
`;

const StyledText = styled.p`
  color: ${colors.black};
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
`;

const StyledList = styled.ul`
  padding-left: 0;
  padding-top: 1rem;
  margin: 0;
`;

const StyledListItem = styled.li`
  margin-bottom: 1rem;
`;

interface PitchAssistProps {
  config: PitchAssistConfig;
}

export const PitchAssist = ({ config }: PitchAssistProps) => {
  return (
    <PitchAssistWrapper>
      <StyledText>{config.title}</StyledText>
      <Accordion>
        {config.collection.map((item, index) => {
          return (
            <AccordionSection
              key={index}
              header={item.settings.header}
              iconSrc={item.settings.iconSrc}
              transitionDuration={item.settings.transitionDuration}
              transitionTimingFunction={item.settings.transitionTimingFunction}
            >
              <StyledList>
                {item.listItems.map((listItem, itemIndex) => (
                  <StyledListItem key={itemIndex}>
                    {listItem.text}
                  </StyledListItem>
                ))}
              </StyledList>
            </AccordionSection>
          );
        })}
      </Accordion>
    </PitchAssistWrapper>
  );
};
