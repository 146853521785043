import { colors } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';

const WelcomePartnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const StyledParagraph = styled.p`
  margin: 1rem 0;
  color: ${colors.black};
  font-size: 60px;
  font-style: normal;
  font-weight: 300;
  line-height: 105%;
`;

interface WelcomePartnerProps {
  firstName: string;
}

export const WelcomePartner = ({ firstName }: WelcomePartnerProps) => {
  const template = firstName ? `Welcome, ${firstName}!` : `Welcome!`;

  return (
    <WelcomePartnerWrapper>
      <StyledParagraph>{template}</StyledParagraph>
    </WelcomePartnerWrapper>
  );
};
