import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import {
  AuthenticationState,
  useAuth,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import { Button } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { PartnerLogo, PartnerLogoProps } from '../partner-logo';

const Container = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 19px 19px 19px 16px;
`;

type HeaderProps = PartnerLogoProps & {
  pageName: string;
  showLoginButton?: boolean;
};

export const Header = ({
  pageName,
  showLoginButton = false,
  logoName,
  logoHeight,
}: HeaderProps) => {
  const { logIn, authState } = useAuth();
  const { dispatchClickAnalytics } = useClickAnalytics();

  const headerClick = () =>
    dispatchClickAnalytics(
      {
        Cta: 'Header Icon',
        ActionId: 'HeaderIcon',
      },
      {
        PageName: pageName,
      }
    );

  const loginClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'Header login button',
        ActionId: 'HeaderLoginButton',
      },
      {
        PageName: pageName,
      }
    );
    logIn();
  };

  return (
    <Container onClick={headerClick}>
      <PartnerLogo logoName={logoName} logoHeight={logoHeight} />
      {showLoginButton && authState !== AuthenticationState.LoggedIn && (
        <Button
          onClick={loginClick}
          variant="outline"
          size="small"
          iconSrc="User"
          iconSide="left"
          style={{ height: '27px', fontSize: '14px' }}
        >
          Sign in
        </Button>
      )}
    </Container>
  );
};
