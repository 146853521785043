import { AffiliateType } from '@3as-affiliates/shared/types-configs';
import { HttpClientResponse } from '@3as-affiliates/shared/utils/fetch-http-client';

export const parseAffiliateInfo = (
  affiliateData: HttpClientResponse<unknown>
) => {
  const parsedInfo: AffiliateType = JSON.parse(affiliateData.body as string);

  return {
    firstName: parsedInfo?.firstName,
    lastName: parsedInfo?.lastName,
    email: parsedInfo?.email,
    phoneNumber: parsedInfo?.phoneNumber,
    state: parsedInfo?.state,
    companyName: parsedInfo?.companyName,
    role: parsedInfo?.role,
    msaTimestamp: parsedInfo?.msaTimestamp,
  };
};
