import { PageContainerWithViewAnalyticsHeaderFooter } from '@3as-affiliates/partners/web/components';
import { PageNames } from '@3as-affiliates/partners/web/types-configs';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import {
  AlertBanner,
  Button,
  colors,
  RadioButton,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AnySchema, object, string } from 'yup';
import { Routes } from '../router';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const Title = styled.p`
  color: ${colors.black};
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 115%;
  margin-bottom: 0;
`;

const Description = styled.p`
  color: ${colors.neutralDeeper};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 115%;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 30px;
  width: 100%;
`;

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: start;
`;

const StyledError = styled.p`
  margin: 5px 0 0 0;
  color: ${colors.errorDeep};
`;

const Spacer = styled.div`
  margin: 10px 0;
  width: 100%;
`;

const StyledTextarea = styled.textarea<{ borderColor?: string }>`
  width: 303px;
  display: flex;
  height: 56px;
  padding: 12px 0 12px 12px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid ${({ borderColor = colors.neutralDeeper }) => borderColor};

  ::placeholder {
    font-family: Apercu Pro,serif;
    overflow: hidden;
    color: ${colors.neutralDeeper}
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
  }
`;

const StyledButton = styled(Button)`
  width: 295px !important;
`;

interface DeclineReasonFormValues {
  reasonEnum: string | null;
  reasonOther: string;
}

interface FormConfig {
  collection: {
    label: string;
    name: string;
    value: string;
    hasInput: boolean;
    nameInput?: string;
    placeholder?: string;
    rows?: number;
  }[];
  formValidationSchema: AnySchema;
  formDefaultValues: DeclineReasonFormValues;
}

const declineReasonPillsConfig: FormConfig = {
  collection: [
    {
      label: 'Cost',
      name: 'reasonEnum',
      value: 'cost',
      hasInput: false,
    },
    {
      label: 'Does not want/need',
      name: 'reasonEnum',
      value: 'notWanted',
      hasInput: false,
    },
    {
      label: 'Needs time to consider',
      name: 'reasonEnum',
      value: 'needsTime',
      hasInput: false,
    },
    {
      label: 'Needs to talk with partner/decision maker',
      name: 'reasonEnum',
      value: 'needsToTalk',
      hasInput: false,
    },
    {
      label: '',
      name: 'reasonEnum',
      value: 'other',
      hasInput: true,
      nameInput: 'reasonOther',
      placeholder: 'Other reason...',
      rows: 3,
    },
  ],
  formValidationSchema: object({
    reasonEnum: string().required(),
    reasonOther: string().when('reasonEnum', {
      is: 'other',
      then: string().required('Please enter a decline reason.'),
    }),
  }),
  formDefaultValues: {
    reasonEnum: null,
    reasonOther: '',
  },
};

export const DeclinePage = () => {
  const navigate = useNavigate();
  const { dispatchClickAnalytics } = useClickAnalytics();
  const { appInfo } = useAppData();
  const [isOpenConfirmationBanner, setIsOpenConfirmationBanner] =
    useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isOpenConfirmationBanner) {
        setIsOpenConfirmationBanner(false);
        navigate(Routes.LoggedInHome);
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [isOpenConfirmationBanner, navigate]);

  const trackDecline = (values: DeclineReasonFormValues) => {
    const reason =
      values.reasonEnum === 'other' ? values.reasonOther : values.reasonEnum;

    const type = values.reasonEnum === 'other' ? 'Other' : 'Predefined';

    dispatchClickAnalytics(
      {
        Cta: 'DeclineReasonSubmitButton',
        ActionId: 'DeclineReason',
      },
      {
        Provider: appInfo?.partner as string,
        Reason: reason as string,
        ReasonType: type as 'Other' | 'Predefined',
      }
    );
  };

  const onDeclineReasonFormSubmit = async (values: DeclineReasonFormValues) => {
    trackDecline(values);

    console.log('Successfully submitted decline reason', values);

    setIsOpenConfirmationBanner(true);
  };

  const { errors, isValid, validateForm, handleChange, values, handleSubmit } =
    useFormik<DeclineReasonFormValues>({
      initialValues: declineReasonPillsConfig.formDefaultValues,
      validationSchema: declineReasonPillsConfig.formValidationSchema,
      onSubmit: onDeclineReasonFormSubmit,
    });

  useEffect(() => {
    validateForm && validateForm();
  }, [validateForm, values]);

  return (
    <PageContainerWithViewAnalyticsHeaderFooter
      pageName={PageNames.DeclinePage}
    >
      <AlertBanner
        isOpen={isOpenConfirmationBanner}
        alertBannerType={'success'}
      >
        {'Decline reason submitted, thank you!'}
      </AlertBanner>
      <TitleWrapper>
        <Title>{'Why did the customer decline today?'}</Title>
        <Description>
          {'*Required. Select an option or add a custom response.'}
        </Description>
      </TitleWrapper>
      <Spacer />
      <StyledForm onSubmit={handleSubmit}>
        <FormControlsWrapper role="radiogroup">
          {declineReasonPillsConfig.collection.map((item) => {
            return (
              <StyledDiv key={item.value}>
                <RadioButton
                  label={item.label}
                  name={item.name}
                  onChange={handleChange}
                  value={item.value}
                  checked={values.reasonEnum === item.value}
                ></RadioButton>
                {item.hasInput && (
                  <div>
                    <StyledTextarea
                      disabled={values.reasonEnum !== item.value}
                      onChange={handleChange}
                      name={item.nameInput}
                      rows={item.rows}
                      placeholder={item.placeholder}
                      value={values.reasonOther}
                      borderColor={
                        errors.reasonOther ? `${colors.errorDeep}` : ''
                      }
                    />
                    {errors?.reasonOther && (
                      <StyledError>{errors.reasonOther}</StyledError>
                    )}
                  </div>
                )}
              </StyledDiv>
            );
          })}
        </FormControlsWrapper>
        <ControlWrapper>
          <Spacer />
          <Spacer />
          <StyledButton disabled={!isValid} type="submit">
            {'Submit'}
          </StyledButton>
        </ControlWrapper>
      </StyledForm>
    </PageContainerWithViewAnalyticsHeaderFooter>
  );
};
