import { WebStorageStateStore } from 'oidc-client-ts';
import { Environment } from './type';

export const environment: Environment = {
  analyticsScope: 'Partners',
  authOpenIdConfig: {
    authority: 'https://stage.asurionid.dev',
    client_id: 'ef78c0fb-8ee8-4e89-8ef8-83e442513def',
    redirect_uri: 'https://partners.feat.npr.affiliates.asurion.com/redirect',
    silent_redirect_uri:
      'https://partners.feat.npr.affiliates.asurion.com/redirect',
    post_logout_redirect_uri:
      'https://partners.feat.npr.affiliates.asurion.com/landing',
    response_type: 'code',
    scope: 'openid offline_access',
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  },
  production: false,
};
