import { GlobalStyle } from '@3as-affiliates/partners/web/styles';
import { SupportedPartners } from '@3as-affiliates/shared/types-configs';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { AnalyticsProvider } from '@3as-affiliates/shared/web/utils/analytics';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { appAnalyticsConfig } from '../initializations';
import { router } from '../router';

const App = () => {
  const { updateAppInfo } = useAppData();

  useEffect(() => {
    // ToDo: Add partner information from URL
    // ToDo: fix dependency on appInfo
    updateAppInfo({ partner: SupportedPartners.Comparion });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnalyticsProvider appAnalyticsConfig={appAnalyticsConfig}>
      <GlobalStyle />
      <RouterProvider router={router} />
    </AnalyticsProvider>
  );
};

export default App;
