import {
  Footer,
  Header,
  PageContainerWithViewAnalytics,
} from '@3as-affiliates/shared/web/components';
import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 660px;
  margin: 0 auto;
`;

interface Props {
  children: ReactNode;
  pageName: string;
}

export const PageContainerWithViewAnalyticsHeaderFooter = ({
  children,
  pageName,
}: Props) => {
  return (
    <PageContainerWithViewAnalytics pageName={pageName}>
      <Header pageName={pageName} />
      <Container>{children}</Container>
      <Footer />
    </PageContainerWithViewAnalytics>
  );
};
