import styled from 'styled-components';

interface VerticalSpacerProps {
  value?: number;
}

const StyledSpacer = styled.div<{ value?: number }>`
  margin: ${({ value = 1 }) => value}rem 0;
  width: 100%;
`;

export const VerticalSpacer = ({ value }: VerticalSpacerProps) => {
  return <StyledSpacer value={value} />;
};
