export enum AppPlatforms {
  MobileApp = 'MobileApp',
  Web = 'Web',
}

export enum SupportedPartners {
  Asurion = 'asurion',
  Comparion = 'comparion',
}

// Pattern: [ProgramName][RoleName]AffiliatesServices
export enum ActiveCampaigns {
  AppliancesPlusTechniciansAffiliateServices = 'appliancesPlusTechniciansAffiliateServices',
}
