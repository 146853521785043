import {
  ButtonSaleDecline,
  ButtonSaleStart,
  HomeBanner,
  PageContainerWithViewAnalyticsHeaderFooter,
  PitchAssist,
  ShareLink,
  TrainingEducation,
  VerticalSpacer,
  WelcomePartner,
} from '@3as-affiliates/partners/web/components';
import { PageNames } from '@3as-affiliates/partners/web/types-configs';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { useAuth } from '@3as-affiliates/shared/web/utils/auth-provider';
import { colors } from '@soluto-private/mx-asurion-ui-react-v3';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Banner from '../partner-data/assets/home-banner.png';
import {
  pitchAssistConfig,
  trainingMaterialsConfig,
} from '../partner-data/content/comparion';
import { Routes } from '../router';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 660px;
  margin: auto;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TrainingEducationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  justify-content: space-between;
`;

const StyledTrainingHeader = styled.p`
  color: ${colors.black};
  font-size: 32px;
  font-weight: 300;
  line-height: 120%;
  margin-bottom: 1rem;
`;

const StyledTrainingCounter = styled.span`
  color: ${colors.neutralDeeper};
  font-size: 24px;
  font-weight: 400;
  line-height: 130%;
`;

const d2cBaseUrl = process.env['NX_D2C_BASE_URL'];

export const HomePage = () => {
  const navigate = useNavigate();
  const { getAuthUserInfo } = useAuth();
  const { dispatchClickAnalytics } = useClickAnalytics();
  const { appInfo, affiliateInfo } = useAppData();

  const handleStartSale = async () => {
    const authUserInfo = await getAuthUserInfo();
    const accessToken = authUserInfo.accessToken;
    const enrollmentForm = document.createElement('form');
    enrollmentForm.method = 'post';
    enrollmentForm.action = `${d2cBaseUrl}/affiliate/comparion/home-plus/personal-info?a_id=eid123`;
    const authField = document.createElement('input');
    authField.name = 'Authorization';
    authField.value = accessToken;
    enrollmentForm.appendChild(authField);
    document.body.appendChild(enrollmentForm);
    enrollmentForm.submit();
  };

  const handleDeclineSale = () => {
    navigate(Routes.LoggedInDecline);
  };

  const handleLaunchTraining = (material: { label: string; url: string }) => {
    console.log('Launching training material', material.label);

    dispatchClickAnalytics(
      {
        Cta: 'TrainingMaterialButton',
        ActionId: 'LaunchTrainingMaterial',
      },
      {
        Provider: appInfo?.partner as string,
        Material: material.label,
        Link: material.url,
      }
    );
  };

  return (
    <PageContainerWithViewAnalyticsHeaderFooter pageName={PageNames.HomePage}>
      <HomeBanner src={Banner} alt={"What's appliance plus"} />
      <Wrapper>
        <WelcomePartner firstName={affiliateInfo?.firstName as string} />
        <ShareLink link={'asur.me/ckX4ac23'} url={'https://asur.me/ckX4ac23'} />
        <VerticalSpacer />
        <ButtonsWrapper>
          <ButtonSaleStart
            onClick={handleStartSale}
            iconSrc={'CardsNavigationChevronRight'}
            iconSize={'xlarge'}
            label={'Start sale'}
          />
          <ButtonSaleDecline
            onClick={handleDeclineSale}
            iconSrc={'CardsNavigationChevronRight'}
            iconSize={'xlarge'}
            label={'Submit a decline'}
          />
        </ButtonsWrapper>
        <VerticalSpacer />
        <PitchAssist config={pitchAssistConfig} />
        <VerticalSpacer />
        <TrainingEducationWrapper>
          <StyledTrainingHeader>
            {'Training + Education'}{' '}
            <StyledTrainingCounter>
              {`(${trainingMaterialsConfig.length})`}
            </StyledTrainingCounter>
          </StyledTrainingHeader>
          {trainingMaterialsConfig.map((material, index) => {
            return (
              <TrainingEducation
                key={index}
                url={material.url}
                label={material.label}
                onClick={() => handleLaunchTraining(material)}
              />
            );
          })}
        </TrainingEducationWrapper>
      </Wrapper>
    </PageContainerWithViewAnalyticsHeaderFooter>
  );
};
