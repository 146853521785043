import { Card, colors, Icon } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import Image from './assets/shield.png';

const ButtonSaleStartWrapper = styled.div`
  display: flex;
`;

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  cursor: pointer !important;
  width: 288px;
  height: 109px;
  border-radius: 8px;
  background: var(
    --gradients-dark-angle-2,
    linear-gradient(285deg, ${colors.primaryDeeper} 0%, #a90ad3 100%)
  );
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const StyledImage = styled.img`
  width: 125px;
  height: 125px;
`;

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
`;

const StyledTitle = styled.p`
  margin: 0;
  color: ${colors.white};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
`;

const StyledIcon = styled(Icon)`
  svg path {
    fill: #ffffff !important;
  }
`;

interface ButtonSaleStartProps {
  onClick: () => unknown;
  label: string;
  iconSrc: string;
  iconSize: string;
}

export const ButtonSaleStart = ({
  onClick,
  label,
  iconSize,
  iconSrc,
}: ButtonSaleStartProps) => {
  return (
    <ButtonSaleStartWrapper>
      <StyledCard onClick={onClick}>
        <CardWrapper>
          <StyledImage src={Image} alt="image" />
          <ControlsWrapper>
            <StyledTitle>{label}</StyledTitle>
            <StyledIcon size={iconSize} src={iconSrc}></StyledIcon>
          </ControlsWrapper>
        </CardWrapper>
      </StyledCard>
    </ButtonSaleStartWrapper>
  );
};
